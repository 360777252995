.rnd {
  border: 3.5px solid #7583ff;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

model-viewer {
--poster-color: transparent;
width: 100%;
height: 100%;
}

model-viewer::part(default-progress-mask) {
display:none;
}

#progress-bar-background {
opacity: 0;
visibility: hidden;
display:inline-block;
width:75%;
max-width: 250px;
border-radius: 6px;
border: 1px solid #BDBEC3;
background-color: #fff;
padding: 12px;
z-index: 100;
/*margin: 20% auto 0 auto;*/
transform: translate3d(-50%, -50%, 0);
position: absolute;
top:50%;
left:50%;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

#progress-bar-a3d {
height:8px;
width:100%;
border-radius: 4px;
border: none;
background-color:#BDBEC3;
text-align: left;
}

#progress-bar-a3d-mask {
height:8px;
width:0%;
border-radius: 4px;
border: none;
background-image:linear-gradient(90deg, #9300C7  0%, #E200DA  50%, #FF00B7 100%);
}
.fadein {
visibility: visible !important;
opacity: 1 !important;
transition: opacity 0.5s linear;
}
.fadeout {
visibility: hidden !important;
opacity: 0 !important;
transition: visibility 0s 0.5s, opacity 0.5s linear;
}

