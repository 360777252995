.content {
    margin: auto;
    margin-bottom: 120px;
    max-width: 1224px;
    width: 100%;
    height: 238px;
    border-radius: 20px;
    background: linear-gradient(90deg, #9A1587 0%, #900A9A 31.77%, #68239D 65.23%, #3A31A5 100%);
    padding-top: 64px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;    
  }
  .dC2Title {
    max-width: 618px;
    width: 100%;
    height: 46px;
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 8px;
    text-align: center;
    color: #FFFFFF;
  }
  .dC2Text {
    max-width: 527px;
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
  }
  .dC2Button {
    padding: 16px 24px;
    margin-bottom: 20px;
    font-weight: 400;
    width: 190px;
    height: 50px;
    font-size: 18px;
    line-height: 18px;
    background: linear-gradient(140.35deg, #FFFCFC 23.25%, #CFCFCF 102.31%, #C4C4C4 178.57%);
    border-radius: 6px;
    text-transform: initial;
  }
  .dC2Button:hover {
    background: #ffffff;
  }