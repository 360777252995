
.dropdown-wrapper {
  /* z-index: 100; */
}
.dropdown-wrapper h3 {
  grid-area: title;
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  margin: 0;
}
.dropdown-wrapper .dropdown {
  grid-area: dropdown;
  position: relative;
  background-color: white;
  border: 1px solid #bdbec3;
  box-sizing: border-box;
  /* z-index: 100; */
  min-width: 98px;
  /* Item Shadow */

  box-shadow: 0px 3px 12px rgba(185, 185, 185, 0.2);
  border-radius: 6px;
}
.dropdown-wrapper .selected-dropdown {
  border: 1px solid #353e5a;
}
.dropdown-wrapper .dropdown h4 {
  color: #353E5A;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  margin: 0;
  margin-right: 1rem;
}
.dropdown-wrapper .dropdown button {
  border: none;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 24px;
}
.dropdown-wrapper .dropdown button img {
  width: 18px;
  height: auto;
}
.arrow {
  /* transition: all ease 0.3s; */
}
.selected-arrow {
  transform: rotate(-180deg);
  /* transition: all ease 0.3s; */
}
.dropdown-wrapper .dropdown .dropdown-list {
  position: relative;
  background-color: white;
  border: 1px solid #353e5a;
  border-top: none;
  box-shadow: 0px 10px 8px -4px rgba(53, 62, 90, 0.2);
  border-radius: 0 0 6px 6px;
  top: 0px;
  right: -1px;
  left: -1px;
  width: 102%;
  display: flex;
  flex-direction: column;
  padding: 12px 14px;
  /* z-index: 200; */
}
.dropdown-wrapper .dropdown button {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 100%;
  color: #353E5A;
  text-decoration: none;
  padding: 14px 10px;
}
.dropdown-wrapper .dropdown button:hover {
  background-color: rgb(0, 0, 0, 0.1);
  border-radius: 6px;
  /* text-decoration: underline !important; */
}

.dropdown-wrapper .dropdown button.selected-link {
  color: #353E5A;
  font-weight: 500;
}
