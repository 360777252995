.date-picker-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.last-days {
  position: absolute;
  right: 27px;
  z-index: 2;
  height: 34px;
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0038a8;
  background: white;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  border-radius: 0 !important;
  padding: 0 10px !important;
  font-weight: normal;
  font-size: 16px !important;
}
.date-picker-title {
  background: white;
  border: 1px solid #dbdbdb;
  border-right: none;
  height: 34px;
  /* width: 50px; */
  display: flex;
  align-items: center;
  padding: 8px;
  padding-right: 0;
  margin-right: 0px;
  border-radius: 4px 0 0 4px !important;
  z-index: 2;
}
.date-picker-arrow {
  background: white;
  border: 1px solid #dbdbdb;
  border-left: none;
  border-radius: 0 4px 4px 0;
  padding: 8px;
  height: 34px;
  display: flex;
  align-items: center;
  margin-left: -7px;
}

.selected-arrow {
  transform: rotate(180deg);
}

.date-picker-title p {
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  margin: 0;
  color: #858993;
}

/* INPUTS */

.DateRangePicker {
  z-index: 2;
}

.DateRangePickerInput__withBorder {
  border-radius: 0;
  width: 250px;
  border-left: none;
  border-right: none;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  white-space: pre;
}

.DateInput {
  border-radius: 4px;
  /* width: max-content; */
  width: 5.5rem;
}

.DateInput,
.DateInput_input {
  height: 32px;
  font-weight: normal;
  font-size: 16px !important;
}

@media screen and (max-width: 1063px) {
  .last-days {
    position: absolute;
    right: 27px;
    z-index: 2;
    height: 34px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0038a8;
    background: white;
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    border-radius: 0 !important;
    padding: 0 10px !important;
    font-weight: normal;
    font-size: 16px !important;
  }
  .DateRangePickerInput__withBorder {
    border-radius: 0;
    width: 125px;
    border-left: none;
    border-right: none;
    padding: 0 5px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    white-space: pre;
  }
}

@media screen and (max-width: 765px) {
  .last-days {
    position: absolute;
    right: 27px;
    z-index: 2;
    height: 34px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0038a8;
    background: white;
    border: 1px solid #dbdbdb;
    border-right: none;
    border-radius: 4px 0 0 4px !important;
    padding: 0 10px !important;
    font-weight: normal;
    font-size: 16px !important;
  }
  .date-picker-title {
    display: none;
  }
  .DateRangePickerInput__withBorder {
    border: 1px solid #dbdbdb;
    border-right: none;
    border-radius: 4px 0 0 4px !important;
    padding: 0 3px !important;
    width: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    white-space: pre;
  }
}

.DateInput_input {
  border-radius: 4px;
  text-align: center;
  padding: 8px;

  color: #0038a8;
}
.DateInput_input__focused {
  border-bottom: none;
}
.DateRangePickerInput_arrow {
  color: #0038a8;
}

/* END OF INPUTS */

/* CALENDAR */

.DateRangePicker_picker {
  border-radius: 8px;
}
.DayPicker_transitionContainer {
  border-radius: 8px;
}
.DayPicker__withBorder {
  border-radius: 8px;
}

.DayPickerNavigation_button__default,
.DayPickerNavigation_button__default:hover,
.DayPickerNavigation_button__default:active,
.DayPickerNavigation_button__default:focus {
  border: none;
}

.DayPickerNavigation_button__disabled {
  opacity: 0.5;
}

.CalendarMonth_caption {
  color: #030e31;
  font-style: normal;
  font-weight: normal !important;
  font-size: 18px;
  text-align: center;
  padding-top: 22px;
  padding-bottom: 37px;
  caption-side: initial;
}

.CalendarDay__default {
  border: none;
  color: #353e5a;
  background: white;
  /* border-radius: 50% 0 0 50%; */
}

.CalendarDay__selected_span {
  background: #69728f;
  /* border: 1px double #33dacd; */
  color: #fff;
  border-radius: 0;
}
.CalendarDay__selected_span:hover {
  background: #353e5a;
  border: none;
  color: #fff;
}

.CalendarDay_selected {
  border-radius: 0 50% 50% 0;
}

.CalendarDay_selected:first-of-type {
  /* border-radius: 50% 0 0 50%; */
}

/* .CalendarDay_selected:nth-of-type(2) {
  border-radius: 0 50% 50% 0;
} */

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #353e5a;
  border: none;
  color: #fff;
  /* border-radius: 0 50% 50% 0; */
}

.CalendarDay_selected:first-of-type,
.CalendarDay_selected:first-of-type:active,
.CalendarDay_selected:first-of-type:hover {
  background: #353e5a;
  border: none;
  color: #fff;
  /* border-radius: 50% 0 0 50%; */
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #fff;
  border: none;
  color: #bdbec3;
}

.DateRangePicker_picker {
  right: -25px !important;
}
@media screen and (max-width: 645px) {
  .DateRangePicker_picker {
    right: -60px !important;
  }
}
@media screen and (max-width: 610px) {
  .DateRangePicker_picker {
    right: -95px !important;
  }
}
@media screen and (max-width: 572px) {
  .DateRangePicker_picker {
    right: -135px !important;
  }
}
/* 
.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-top: 26px solid transparent;
  border-right: 33px solid #69728f;
  bottom: 0;
  right: 0;
  display: none;
} */

/* END OF CALENDAR */
