.sliderBreakRow {
    flex-wrap: wrap;
}
.sliderBreak1 {
    flex-basis: 45%;
}
.sliderBreak2 {
    flex-basis: 27%;
}

@media screen and (max-width: 940px) {
    .sliderBreak1 {
        flex-basis: 100%;
    }
    .sliderBreak2 {
        flex-basis: 100%;
    }
}