.timeline-chart-wrapper {
  margin-top: 40px;
}
.timeline-chart-container {
  position: relative;
  display: flex;
  align-items: center;
}
.timeline-chart-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.timeline-chart-header h3 {
  margin-bottom: 0 !important;
}
.tooltip-data-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tooltip-data {
  display: flex;
  align-items: center;
}
.dot {
  border-radius: 50%;
  height: 12px !important;
  width: 12px !important;
  z-index: 100;
  margin-right: 10px;
}
.blue-dot {
  background: #0038a8;
}
.grey-dot {
  background: #858993;
}
.timeline-chart-legend {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);

  display: flex;
  align-items: center;
}

.timeline-chart-legend p {
  margin-bottom: 0;
}

.my-tooltip-class:after {
  content: "";
  position: absolute;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: 100%;
  width: 0;
  height: 0;
  border-top: solid 10px white;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
}
