.table-wrapper {
  /* margin: 40px 0; */
  /* overflow-x: scroll; */
  overflow-x: auto;
  overflow-y: visible;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: -42px !important;
}
.table-wrapper-divided {
  margin: 7px 0 !important;
}
.divided-box .table-skeleton {
  margin-top: 40px;
}

.table-skeleton-shared-box {
  margin: 40px 0 20px;
}

.table-wrapper p {
  margin: 0;
}

.table-top-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table-top-header-divided {
  padding-bottom: 23px;
  border-bottom: 1px solid #dddee0;
}
.table-top-header h3 {
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

input.search {
  background: url("../../Assets/images/icons/search-icon.svg") no-repeat scroll 8px 50%;
  padding-left: 40px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  height: 40px;
  width: 300px;
  margin-left: auto;
  margin-right: 32px;
}

.table-wrapper table {
  width: 100%;
  margin: 20px 0 40px;
  border-collapse: separate;
  border-spacing: 0 16px;
}

.table-wrapper table thead tr {
  border-bottom: 1px solid #bdbec3;
  box-shadow: 0px 3px 12px rgba(185, 185, 185, 0.2);
}

.table-wrapper th {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding: 20px 15px;
  text-align: center !important;
  text-transform: uppercase;
}

.table-wrapper th .th-inner-wrapper {
  display: flex;
  justify-content: center;
  white-space: nowrap;
}

.table-wrapper th:first-child {
  text-align: left !important;
}
.table-wrapper tr {
  /* background: transparent; */
  border-radius: 8px;
  box-shadow: 0px 3px 12px rgba(185, 185, 185, 0.2);
}
.table-wrapper tr:nth-child(even) {
  /* background: #f2f2f3; */
}

.table-wrapper td {
  height: 72px;
  padding: 0 15px;
}

.table-wrapper td:first-child {
  /* border-left-style: solid; */
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  /* border-radius: 8px 0 0 8px; */
}
.table-wrapper td:last-child {
  /* background: #ffffff; */
  /* border-radius: 8px; */
  /* border-right-style: solid; */
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  /* border-radius: 0 8px 8px 0; */
}
.table-wrapper td {
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
}
.table-wrapper td .subscript {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 100%;
  margin-top: 8px !important;
}
.table-wrapper td a {
  color: #353e5a;
}

button.sort-arrow {
  background: transparent;
  padding: 0 !important;
  margin-left: 0.5rem !important;
  /* float: right; */
}

.sort-arrow-up {
  transform: rotate(180deg);
}

.pagination-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 0 120px;
  background: #ffffff;
  box-shadow: 0px 2px 16px rgba(40, 41, 61, 0.08);
  border-radius: 8px;
  padding: 17px 0;
  width: 100%;
  min-width: 500px;
}
.pagination p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
}
.pagination-pages {
  display: flex;
  align-items: center;
  justify-content: center;
}

button.pagination {
  border: none;
  background: transparent;
  margin: 0 12px !important;
  padding: 0 !important;
}
button.pagination img {
  width: 2rem;
  height: auto;
}
button.pagination img.active {
  filter: brightness(0) saturate(100%) invert(7%) sepia(58%) saturate(2152%) hue-rotate(212deg) brightness(102%)
    contrast(107%);
}
button.pagination-next img {
  transform: rotate(180deg);
}

button.page-number {
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 50% !important;
  padding: 0 !important;
  margin: 0 8px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #353e5a;
  background: transparent;
}

button.page-number-active {
  color: white;
  background: #353e5a;
}

.direct-to-page {
  display: flex;
  align-items: center;
  color: #353e5a;

  input[type="number"] {
    text-align: center;
    width: 60px;
    height: 32px;
    margin: 0 1rem;
    padding: 0 !important;
    border: 1px solid #353e5a;
    border-radius: 6px;
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  form {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button.go-to-page {
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
  }
  button.go-to-page img {
    height: 12px;
    width: auto;
    margin-left: 4px;
    filter: brightness(0) saturate(100%) invert(7%) sepia(58%) saturate(2152%) hue-rotate(212deg) brightness(102%)
      contrast(107%);
  }
}
